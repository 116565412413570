<template>
  <div>
    <v-dialog
      v-model="open"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="scale-transition"
      scrollable
      persistent
      max-width="500px"
    >
      <v-card flat>
        <v-card-title>
          {{ $t("e_test.copy_question") }}
          <div style="position: absolute; right: 25px;">
            <v-icon @click="closeDialog">mdi-close</v-icon>
          </div>
        </v-card-title>
        <v-card-text
          :style="stepPosition === 1 ? 'height: 200px;' : 'height: 450px;'"
          class="mt-2"
          style="overflow-y: auto;"
        >
          <v-stepper v-model="stepPosition" class="elevation-0">
            <hr />
            <v-stepper-header class="elevation-0">
              <v-stepper-step :complete="stepPosition > 1" step="1">
                <span>{{
                  $t("app.select") + " " + $t("e_test.question")
                }}</span>
              </v-stepper-step>
              <v-divider />
              <v-stepper-step :complete="stepPosition > 2" step="2">
                <span>{{ $t("e_test.showed") }}</span>
              </v-stepper-step>
            </v-stepper-header>
            <hr />

            <v-stepper-items>
              <v-stepper-content step="1" class="stepper-content">
                <v-select
                  v-model="bodyRequest.question_bank"
                  :disabled="disableButton"
                  :items="questionBank"
                  :label="$t('e_test.select_question_from_etest')"
                  :item-text="item => truncateHtml(item.question)"
                  :loading="questionLoading"
                  item-value="question_bank"
                  multiple
                  outlined
                  hide-details
                  dense
                  class="mt-2"
                >
                  <template slot="prepend-item">
                    <v-list-item @click="handleClickAll()">
                      <v-list-item-action>
                        <v-checkbox :value="selectAllQuestion"></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content
                        :class="selectAllQuestion && 'primary--text'"
                      >
                        {{ $t("app.select_all") }}
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <span
                      v-html="truncateHtml(item.question)"
                      v-if="index === 0"
                      class="mr-2"
                    />
                    <span
                      v-if="index === 1 && bodyRequest.question_bank"
                      class="grey--text"
                    >
                      (+{{ bodyRequest.question_bank.length - 1 }} others)
                    </span>
                  </template>
                </v-select>
              </v-stepper-content>

              <v-stepper-content step="2" class="stepper-content">
                <v-data-table
                  v-model="idClass"
                  :headers="tableHeaders"
                  :items="classList"
                  :loading="loadingTable"
                  disable-pagination
                  item-key="class_id"
                  show-select
                  hide-default-footer
                  class="elevation-0"
                >
                  <template
                    #item.data-table-select="{ item, isSelected, select }"
                  >
                    <v-icon
                      v-if="info.class == item.class_id"
                      color="grey lighten-1"
                    >
                      mdi-checkbox-marked
                    </v-icon>
                    <v-simple-checkbox
                      v-else
                      :value="isSelected"
                      @input="select($event)"
                    />
                  </template>
                </v-data-table>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
        <v-divider class="my-3" />
        <v-card-actions>
          <v-row no-gutters>
            <v-col cols="12" v-if="stepPosition === 1" class="d-flex">
              <div>
                <v-btn
                  outlined
                  dark
                  color="primary"
                  class="mr-2"
                  @click="closeDialog"
                  >{{ $t("app.cancel") }}</v-btn
                >
              </div>
              <v-spacer />
              <div>
                <v-btn
                  color="gradient-primary"
                  depressed
                  dark
                  @click="nextStep(1)"
                >
                  {{ $t("app.next") }}
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" v-else class="d-flex">
              <div>
                <v-btn
                  outlined
                  dark
                  color="primary"
                  class="mr-2"
                  @click="prevStep(2)"
                  >{{ $t("app.back") }}</v-btn
                >
              </div>
              <v-spacer />
              <div>
                <v-btn
                  :loading="loadingSave"
                  color="gradient-primary"
                  depressed
                  dark
                  @click="save"
                >
                  {{ $t("e_test.copy_question") }}
                  <v-icon class="ml-2">mdi-content-copy</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  copyQuestionBankLocal,
  getClassEtest
} from "@/api/admin/academic/eTest";
import truncate from "truncate-html";
import { questionList } from "@/api/admin/academic/eTest";

export default {
  props: {
    open: Boolean,
    info: Object
  },
  computed: {
    idClass: {
      set(value) {
        this.modelTable = value;
        if (value.length) {
          this.bodyRequest.class_to = this.modelTable.map(d => d.class_id);
        }
      },
      get() {
        return this.modelTable;
      }
    }
  },
  watch: {
    open(value) {
      if (value) {
        this.getAllList();
        this.eTestLesson();
        this.bodyRequest.etest_class_from = this.info.etest_class;
      }
    },
    "bodyRequest.question_bank": {
      handler(data) {
        if (data.length === this.questionBank.length) {
          this.selectAllQuestion = true;
        } else {
          this.selectAllQuestion = false;
        }
      }
    }
  },
  data() {
    return {
      questionBank: [],
      selectAllQuestion: false,
      questionLoading: false,
      modelTable: [],
      loadingTable: false,
      tableHeaders: [
        {
          text: this.$i18n.t("app.class"),
          value: "class_name"
        }
      ],
      disableButton: false,
      loadingSave: false,
      stepPosition: 1,
      classList: [],
      bodyRequest: {
        question_bank: [],
        class_to: [],
        etest_class_from: null
      }
    };
  },
  methods: {
    async eTestLesson() {
      this.questionLoading = true;
      try {
        const response = await questionList({
          id: this.$route.params.id
        });
        const data = response.data;
        if (data.code) {
          this.questionBank = data.data;
        } else {
          this.snackBar(false, data.message);
        }
      } catch (error) {
        console.error("eTestLesson()\n", error);
      } finally {
        this.questionLoading = false;
      }
    },
    handleClickAll() {
      this.selectAllQuestion = !this.selectAllQuestion;
      this.bodyRequest.question_bank = [];
      if (this.selectAllQuestion) {
        this.bodyRequest.question_bank = this.questionBank.map(
          question => question.question_bank
        );
      }
    },
    truncateHtml: html =>
      truncate(html, 10, { byWords: true, stripTags: true }),
    async getAllList() {
      this.loadingTable = true;
      try {
        const response = await getClassEtest({ etest: this.info.etest });
        if (response.data.code === 1) {
          this.classList = response.data.data;
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        console.error("getAllList()\n", error);
      } finally {
        this.loadingTable = false;
      }
    },
    async save() {
      if (this.bodyRequest.class_to.length) {
        const etest = { etest: this.info.etest };
        this.loadingSave = true;
        try {
          const response = await copyQuestionBankLocal({
            ...this.bodyRequest,
            ...etest
          });
          if (response.data.code) {
            this.snackBar(
              true,
              this.$i18n.t("e_test.msg.success_copy_questions")
            );
            this.closeDialog();
          } else {
            this.snackBar(false, response.data.message);
          }
        } catch (error) {
          console.error("save()\n", error);
        } finally {
          this.loadingSave = false;
        }
      } else {
        this.snackBar(false, this.$i18n.t("e_test.msg_minimal_class"));
      }
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    nextStep(n) {
      if (n === this.steps) {
        this.stepPosition = 1;
      } else {
        switch (n) {
          case 1:
            if (this.bodyRequest.question_bank.length) {
              this.stepPosition = n + 1;
              break;
            } else {
              this.snackBar(false, this.$i18n.t("e_test.msg_minimal_question"));
              break;
            }
        }
      }
    },
    prevStep(n) {
      if (n === 1) {
        this.stepPosition = 1;
      } else {
        this.stepPosition = n - 1;
      }
      this.$vuetify.goTo(150);
    },
    closeDialog() {
      this.stepPosition = 1;
      this.bodyRequest = {
        question_bank: [],
        class_to: [],
        etest_class_from: null
      };
      this.modelTable = [];

      this.$emit("toggleDialog");
    }
  }
};
</script>
